<template>
  <div style="padding-top: 64px">
    <el-carousel :height="`${imageHeight}px`" :interval="5000">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <div class="banner">
          <div
            class="banner-container"
            :class="item.id === 6 ? 'information' : ''"
          >
            <div class="title">{{ item.title1 }}</div>
            <div class="title">{{ item.title2 }}</div>

            <div class="tips">{{ item.tips1 }}</div>
            <div class="tips" v-if="item.id !== 6">{{ item.tips2 }}</div>
            <div
              class="button"
              :class="item.id === 6 ? 'information-button' : ''"
              @click="goForm(item.id)"
            >
              {{ item.id === 6 ? "前往查看" : "立即咨询" }}
              <img
                v-if="item.id !== 6"
                src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/home-arrow-right.png"
                alt=""
                class="icon"
              />
              <img
                v-else
                src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/arrow-right.png"
                alt=""
                class="icon"
              />
            </div>
          </div>
          <div class="img">
            <img :src="item.img" :alt="item.alt" :style="imageStyle" />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<style scoped lang="scss">
@import "./index.scss";
</style>

<script>
export default {
  name: "carouselCom",
  data() {
    return {
      carouselList: [
        {
          id: 1,
          width: "500",
          height: "134",
          title1: "为企业打造",
          title2: "一站式法律合规风险管理解决方案",
          tips1: "融合法律智慧与科技力量",
          tips2: "以科技引领，数智共创未来",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner1.png",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner1-title.png",
          alt:
            "为企业提供一站式合规解决方案，融合法律智慧与科技力量，推动科技驱动合规发展",
        },
        {
          id: 2,
          width: "580",
          height: "134",
          title1: "EyeTrust",
          title2: "企业管理体系建设平台",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner2-title.png",
          tips1: "以客观的云评测自动识别风险",
          tips2: "助力企业管理体系建设",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner2.png",
          alt:
            "Eyetrust合规管理体系建设平台，合规眼，以客观的云评测自动识别企业合规风险助力企业合规管理体系建设",
        },
        {
          id: 3,
          width: "472",
          height: "134",
          title1: "达芬奇",
          title2: "智能合同管理系统",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner3-title.png",
          tips1: "赋能企业合同管理全流程",
          tips2: "让合同更简单、更智能、更安全",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner3.png",
          alt:
            "达芬奇智能合同管理系统，赋能企业合同管理全流程，让合同更简单、更智能、更安全",
        },
        {
          id: 4,
          width: "354",
          height: "134",
          title1: "达芬奇",
          title2: "智能涉诉系统",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner4-title.png",
          tips1: "全面感知诉讼风险",
          tips2: "让企业法务工作高效、智能、安全、可信",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner4.png",
          alt:
            "达芬奇智能涉诉系统，全面感知诉讼风险，让企业法务工作高效、智能、安全、可信",
        },
        {
          id: 5,
          width: "590",
          height: "134",
          title1: "LawTrust",
          title2: "专项风险治理平台",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner5-title.png",
          tips1: "提供数据隐私管理、风险管理、数据治理服务",
          tips2: "致力于提高企业的数据隐私管理能力",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner5.png",
          alt:
            "LawTrust数据合规智能管理系统，提供隐私合规、风险管理、数据治理服务致力于提高企业的数据合规能力",
        },
        {
          id: 6,
          width: "235",
          height: "67",
          title1: "最新资讯",
          title:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner6-title.png",
          tips1: "轻松了解iLaw最新资讯",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/banner6.png",
          alt: "iLaw合规最新资讯",
        },
      ],
      originalWidth: 1920, // 原始宽度
      originalHeight: 644, // 原始高度
      aspectRatio: 1920 / 644, // 宽高比
      currentWidth: window.innerWidth, // 当前屏幕宽度
      carouselHeight: "420px", // 初始高度
    };
  },
  computed: {
    // 计算图片的高度
    imageHeight() {
      return this.currentWidth / this.aspectRatio;
    },
    // 返回图片的样式对象
    imageStyle() {
      return {
        width: "100%", // 宽度100%
        height: `${this.imageHeight}px`, // 动态高度
        objectFit: "cover", // 如果需要，可以裁剪图片以填充整个容器
      };
    },
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    goForm(id) {
      const url = window.location.origin;
      if (id !== 6) {
        window.open(`${url}/appointment`);
      } else {
        window.open(`${url}/resourceCentre`);
      }
    },
    handleResize() {
      // 更新当前屏幕宽度
      this.currentWidth = window.innerWidth;
    },
    handleImageLoad(index, imageHeight) {
      if (index === 0) {
        this.carouselHeight = `${imageHeight}px`;
      }
    },
  },
};
</script>
